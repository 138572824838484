import { Typography} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState,useEffect,useContext } from 'react';
import { useParams } from 'react-router';
import { AuthContext } from '../../context/AuthContext';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Divider } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThanksEmoji,CopyLink } from '../../assets';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import { Avatar } from '@mui/material';
import { postArticleActions,postReportComment } from '../../api';
import { getArticle, setSaved,markArticleAsRead,getJournalClubArticle } from '../../state/actions/article';
import { getJournalClubDetails,getJCUserDetails,getJournalClubs } from '../../state/actions/journalclub';
import { reportList } from '../../constants/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root':{
        padding: '8px 24px'
    },
    '& .MuiIconButton-root':{
        padding: '0px'
    },
    '& .MuiFormControlLabel-label':{
        fontFamily:'Raleway!important'
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialog-paper':{
        width:'100%',
        maxWidth: '100%',
        height:'100%',
        maxHeight:'100%',
        borderRadius:'0px',
        margin:0,
      }
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper':{
        width:'40%',
        height:'68%'
      }
    },
}));

const BootstrapDialogMedium = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        },
        '& .MuiDialogTitle-root':{
            padding: '8px 24px'
        },
        '& .MuiIconButton-root':{
            padding: '0px'
        },
        [theme.breakpoints.down('md')]: {
        '& .MuiDialog-paper': {
            width: '80%',
            height: 'auto',
        }
        },
        [theme.breakpoints.up('md')]: {
        '& .MuiDialog-paper': {
            width: '30%',
            height: 'auto',
        }
    },
}));
  
const BootstrapDialogTitle = (props) => {
const { children, onClose, ...other } = props;

return (
    <DialogTitle sx={{ m: 0, p: 2}} {...other}>
    {children}
    {onClose ? (
        <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
            position: 'absolute',
            right: 8,
            top: 15,
            color: '#fff',
        }}
        >
        <CloseIcon />
        </IconButton>
    ) : null}
    </DialogTitle>
);
};
  
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function ReportComment(props) {
    const { open,onClose,id } = props;
    const [value,setValue] = useState('')
    const [reportError,setReportError] = React.useState(false);
    const [openThanks,setOpenThanks] = React.useState(false);
    const { PMID,JCID } = useParams()
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const userEmail = auth.user.email;
    const {articleData} = useSelector((state) => state.article)
    const { jcList } = useSelector((state) => state.journalclub);
    const JCPage = JCID ? JCID === 'J00000000' ? false : true : false
  

    useEffect(() => {
        if(value === ''){
            setReportError(true) 
        }
    }, [value])

    const reportButtonHandleClose = () => {
        const data = {
            type : value
        }
        setReportError(true)
        postReportComment(id,userEmail,data).then(response =>{
            onClose()
            setValue('') 
            setOpenThanks(true)
        }).catch(error =>{
            console.error(error)
        })
        
    }

    const onChangeValue = (event) => {
        setValue(event.target.value)
        setReportError(false) 
    }
    const thanksHandleClose = () =>{
        setOpenThanks(false)
    }

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <BootstrapDialogTitle sx={{background: '#EF5B50', color: '#fff'}} id="customized-dialog-title" onClose={onClose} >
                    <Typography variant="H3_RALEWAY" pl={{xs:0,md:1}}>
                        Report a Comment
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent>
                <Box pl={{xs:0,md:2}} mt={2} sx={{fontFamily:'Raleway!important'}}>
                    <Box pb={2}>
                        <Typography variant="H3_RALEWAY">
                            Why are you reporting this?
                        </Typography>
                    </Box>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={onChangeValue}>
                        {reportList.map((report,idx) => 
                        <Box key={idx}>
                            <Box sx={{display:'inline-block'}}>
                                <FormControlLabel value={report} control={<Radio />} label={report} />
                            </Box>
                        </Box>
                        )}   
                  </RadioGroup>
                </Box>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'center'}}>
                <Button variant='contained' type="submit" onClick={reportButtonHandleClose} disabled={reportError}>
                    <Typography variant='H3_RALEWAY'>
                        Report
                    </Typography>
                </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialogMedium
                onClose={thanksHandleClose}
                aria-labelledby="customized-dialog-title"
                open={openThanks}>
                <BootstrapDialogTitle sx={{background: '#EF5B50', color: '#fff'}}onClose={thanksHandleClose} >
                    <Typography variant="H3_RALEWAY">
                        Report a Comment
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent>
                    <Box sx={{ textAlign: 'center' }} pt={2} pl={2} pr={2}>
                        <Box pl={{ xs: 2, md: 2 }} pr={{ xs: 2, md: 2 }} mb={2}>
                            <Typography variant="h6"
                                sx={{
                                    fontFamily: 'bitter',
                                    fontWeight: '600'
                                }}
                                gutterBottom>
                                Thank you for reporting this.
                            </Typography>
                            <Box mt={{ xs: 2, md: 2 }}>
                                <Divider variant="inset" sx={{ width: '40%', margin: 'auto', border: '1px solid #EF5B50' }} />
                            </Box>
                        </Box>
                        <Typography variant="BODY_COPY2_RALEWAY_DESKTOP"
                            sx={{
                                fontSize: '14px',
                                lineHeight: '24px',
                                fontWeight: '500'
                            }}
                            gutterBottom mb={2}>
                            We appreciate you letting us know. We will look further into this 
                            and shall do the needful. 
                        </Typography>
                        
                    </Box>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'center',paddingBottom:'20px!important'}}>
                <Button variant='contained' type="submit" onClick={thanksHandleClose}>
                    <Typography variant='H3_RALEWAY'>
                        Done
                    </Typography>
                </Button>
                </DialogActions>
            </BootstrapDialogMedium>
            
        </React.Fragment>
    )
}

export default ReportComment