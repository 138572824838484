import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Avatar } from "@mui/material";
import Link from "@mui/material/Link";
import Hidden from "@mui/material/Hidden";
import ActionComponent from "./ActionComponent";
import EngagementComponent from "./EngagementComponent";
import SaveIcon from "../../assets/SaveIcon";
import ShareIcon from "../../assets/ShareIcon";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { Chip } from "@mui/material";
import KeywordChip from "./KeywordChip1";
import { useSelector, useDispatch } from "react-redux";
import {
  acquireLockAndPerformNextAction,
  getDenovoEditHistory,
  getJournalClubArticle,
  resetDenovoLockState,
  setSaved,
} from "../../state/actions/article";
import { AuthContext } from "../../context/AuthContext";
import Snackbar from "@mui/material/Snackbar";
import { SnackbarContent } from "@mui/material";
import CommentNotes from "./CommentNotes";
import PublishReject from "./PublishReject";
import SuppotArticle from "./SuppotArticle";
import DeNovoArticleStatus from "./DeNovoArticleStatus";
import DiscussionActions from "./DiscussionActions";
import ActiveJC from "./ActiveJC";
import { Tabs, tabsClasses } from "@mui/material";
import { CMEIcon } from "../../assets/SVG";
import { getJournalClubs } from "../../state/actions/journalclub";
import article from "../../state/reducers/article";
import { Navigate, useLocation, useNavigate } from "react-router";
import { DENOVO_ARTICLE_STATUS, MEMBERSHIP_STATUS, USER_ROLES } from "../../constants/constants";
import ArticleDenovoView from "./ArticleDenovoView";
import { Edit } from "@mui/icons-material";
import DenovoEditHistoryComponent from "./DenovoEditHistoryComponent";
import { convertToRender } from "../../utils/JCWysiwygEditor";
import * as api from "../../api";
import JCDetailsFullScreenMobile from "../../pages/JCDetails/containers/JCDetailsFullScreenMobile";
import Spacer from "./Spacer";
import JCDetailsSingleTrayMobile from "../../pages/JCDetails/containers/JCDetailsSingleTrayMobile";
import NonJCMemberPopUp from "../../pages/JCDetails/components/NonJCMemberPopUp";
import GuestViewArticleDetailed from "../../pages/GuestPages/GuestViewArticleDetailed";
import ArticlePageHelmet from "../../seo/ArticlePageHelmet";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#262626",
  padding: theme.spacing(1),
  textAlign: "initial",
  [theme.breakpoints.down("md")]: {
    borderRadius: "None",
    padding: 0,
    boxShadow: "None",
  },
}));

const TabsList = styled(Tabs)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  [`& .${tabsClasses.scrollButtons}`]: {
    "&.Mui-disabled": { opacity: 0.3 },
  },
  "& .MuiTabs-indicator": { backgroundColor: "#FFFFFF" },
  [theme.breakpoints.up("md")]: {
    width: "80%",
    "*": {
      marginRight: "5px",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    "*": {
      marginRight: "5px",
    },
  },
}));
const JcChip = ({ jcName, jcColor, bgColor }) => {
  return (
    <Chip
      avatar={
        <Avatar
          sx={{
            bgcolor: jcColor,
            color: "#FFFFFF!important",
            fontFamily: "RALEWAY!important",
            fontWeight: "600!important",
            fontSize: "14px!important",
            display: "inline-flex",
          }}
        >
          {jcName
            .replace(/The |the |a |A |an |An /, "")
            .trim()
            .charAt(0)}
        </Avatar>
      }
      label={jcName}
      style={{
        backgroundColor: bgColor,
        fontFamily: "RALEWAY",
        fontSize: "16px",
        color: "#262626",
        fontWeight: "600",
      }}
    />
  );
};

function ArticleDetailedView(props) {
  const { isJCPage } = props;
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const theme = useTheme();
  const mobileMatch = useMediaQuery(theme.breakpoints.down("md"));
  const boxWidth = mobileMatch ? "80vw" : "55vw";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isFetchingUserRoleInJC, jcList, jcDetails, jcUserDetails } = useSelector(
    (state) => state.journalclub
  );
  const {
    isLoading,
    articleData,
    error,
    denovoArticleInEditMode,
    denovoArticleData,
    dirty,
    denovoLockState,
  } = useSelector((state) => state.article);
  const [publishJCIds, setPublishJCIds] = React.useState([]);
  const [cmeSupportersList, setCmeSupportersList] = React.useState([]);
  const [toastUrlMsg, setToastUrlMsg] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [rejectModel, setRejectModel] = React.useState(false);
  const { isFetchingDetails } = useSelector((state) => state.journalclub);

  useEffect(() => {
    function updatePublishedIds() {
      if (jcList.length == 0) {
        dispatch(getJournalClubs(userEmail));
      }
      if (isJCPage && articleData.journalClubs) {
        const modifiedList = articleData.journalClubs
          .filter((jc) => jc.articleStatus === "Published")
          .map((jc) => jc.JCID);
        setPublishJCIds(modifiedList);
      }
      if (isJCPage && jcDetails.institutions) {
        setCmeSupportersList(
          jcDetails.institutions.filter((inst) => inst.cmeSupporter)
        );
      }
    }
    updatePublishedIds();
  }, []);

  useEffect(() => {
    var lastScrollTop = 0;
    function changeTop() {
      var rightNavElement = document.querySelector(".rightnav");
      const scrollHeight = rightNavElement.clientHeight < 580 ? 407 : 602
      var currScroll = this.scrollY;
      if (rightNavElement !== null) {
        if (currScroll > lastScrollTop) {
          rightNavElement.style.top =
            scrollHeight - rightNavElement.clientHeight + 64 + "px";
        } else {
          rightNavElement.style.top = "64px";
        }
        lastScrollTop = currScroll;
      }
    }

    if (!mobileMatch) {
      window.addEventListener("scroll", changeTop, false);
    }
    return () => window.removeEventListener("scroll", changeTop, false);
  }, []);

  const handleOnShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: articleData.title,
          url: document.location.href,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  };

  const isAdmin = (userRole) => {
    if (userRole === "Administrator" || userRole === "Course Director") {
      return true;
    } else {
      return false;
    }
  };
  const isContributor = (userRole) => {
    if (
      userRole === "Administrator" ||
      userRole === "Course Director" ||
      userRole === "Contributor"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isReader = (userRole) => {
    if (
      userRole === USER_ROLES.ADMINISTRATOR ||
      userRole === USER_ROLES.COURSE_DIRECTOR ||
      userRole === USER_ROLES.CONTRIBUTOR ||
      userRole === USER_ROLES.READER
    ) {
      return true;
    } else {
      return false;
    }
  };



  useEffect(() => {
    if (isLoading === false) {
      if (isFetchingUserRoleInJC === false) {
        if (articleData?.isDeNovo && articleData?.status === DENOVO_ARTICLE_STATUS.DRAFT && isContributor(jcUserDetails?.role) === false) {
          // Denovo Draft article viewed by a user who is Not Contributor and Above
          window.location = "/feed"
        }
      }
      if (articleData?.isDeNovo && isJCPage === false) {
        // Denovo article opened as a normal article
        let userIsMemberInJCsArticleIsPublishedIn = articleData?.journalClubs?.filter(obj => obj.isUserAMember === true && obj.articleStatus === "Published");
        let userCannotViewArticle = userIsMemberInJCsArticleIsPublishedIn === null || userIsMemberInJCsArticleIsPublishedIn?.length === 0;
        let userCanViewArticle = userIsMemberInJCsArticleIsPublishedIn !== null && userIsMemberInJCsArticleIsPublishedIn?.length > 0;

        let articlePublishedIn = articleData?.journalClubs?.filter(obj => obj.articleStatus === "Published");
        let articleJCUrl = `/jc/${articlePublishedIn[0]?.urlSlug}/${articleData?.PMID}`

        if (isReader(jcUserDetails?.role) === false) {
          // user not member of JC in which article is published
          window.location = "/feed";
        } else if(articlePublishedIn && articlePublishedIn.length > 0){
          window.location = articleJCUrl
        }
      }
    }
  }, [isLoading, isFetchingUserRoleInJC]);




  useEffect(() => {
    if (isFetchingDetails === false) {
      dispatch(getDenovoEditHistory(jcDetails?.JCID, articleData?.PMID));

    }
  }, [isFetchingDetails]);


  const handleUrlClick = () => {
    if (articleData.url.replaceAll(" ", "") === "") {
      setToastUrlMsg(true);
    } else {
      window.open(articleData.url, "_blank");
    }
  };
  const handleUrlClose = () => {
    setToastUrlMsg(false);
  };

  const handlePublishClickOpen = () => {
    dispatch(
      acquireLockAndPerformNextAction({
        JCID: jcDetails?.JCID,
        PMID: articleData?.PMID,
        userEmail,
        nextAction: () => {
          setConfirmOpen(true);
          setRejectModel(false);
        }
      })
    );

  };
  const handleRejectClickOpen = () => {
    dispatch(
      acquireLockAndPerformNextAction({
        JCID: jcDetails?.JCID,
        PMID: articleData?.PMID,
        userEmail,
        nextAction: () => {
          setConfirmOpen(true);
          setRejectModel(true);
        }
      })
    )

  };
  const handlePublishClose = () => {
    setConfirmOpen(false);
    dispatch(resetDenovoLockState());
  };
  const getJCIconColor = (JCID) => {
    return jcList.filter((JC) => JC.JCID === JCID)[0].iconColor;
  };

  if (
    isJCPage &&
    (jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITED ||
      jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED ||
      jcDetails.membershipStatus === MEMBERSHIP_STATUS.ADD ||
      jcDetails.membershipStatus === MEMBERSHIP_STATUS.LEFT ||
      jcDetails.membershipStatus === MEMBERSHIP_STATUS.REQUESTED ||
      jcDetails.membershipStatus === MEMBERSHIP_STATUS.REQUEST_REJECTED ||
      jcDetails.membershipStatus === MEMBERSHIP_STATUS.REMOVED)
  ) {

    return (
      <>
        <NonJCMemberPopUp
          open={true}
          jcDetails={jcDetails}
        />
        <GuestViewArticleDetailed
          isJCPage={isJCPage}
          jcMembershipStatus={jcDetails?.membershipStatus}
          jcDetails={jcDetails?.membershipStatus ? jcDetails : null}
          userEmail={jcDetails?.membershipStatus ? userEmail : null}
        />
      </>
    )

  } else {
    return (
      <Box
        my={{
          xs: isJCPage && !denovoArticleInEditMode ? 4 : 0,
          md: 0,
        }}
      >
      {articleData &&
        <ArticlePageHelmet data={articleData} />
        }
        <Grid container>
          {/* Article Details from Pubmed */}
          {(
            (articleData?.isDeNovo === false || articleData?.isDeNovo === null) ||
            (denovoArticleInEditMode === false || denovoArticleInEditMode === null) ||
            (articleData?.isEditable === false || articleData?.isEditable === null)
          ) && (
              <Grid
                item
                xs={12}
                md={9}
                sx={{
                  minWidth: { xs: "100vw", md: "50vw", xl: "40vw" },
                }}

              >
                {/* Proposed By*/}
                {isJCPage && (
                  <Item sx={{ marginBottom: { xs: "8px", md: "8px" } }}>
                    <Hidden mdDown>
                      <Box
                        px={{ xs: 0, sm: 0 }}
                        pt={{ xs: 2, sm: 0, md: 1 }}
                        pb={{ xs: 0, sm: 0, md: 1 }}
                        mx={{ xs: 2, md: 0 }}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="H2_BITTER"
                          sx={{
                            marginLeft: "24px",
                            display: "inline-block",
                            wordWrap: "break-word",
                            //overflow: 'hidden',
                            //whiteSpace: 'nowrap',
                            //textOverflow: 'ellipsis',
                            //minWidth: '60%',
                            textTransform: "capitalize",
                          }}
                        >
                          {jcDetails?.name}
                        </Typography>

                        {articleData?.isDeNovo &&
                          articleData.status !== "Published" &&
                          articleData.status !== "Rejected" &&
                          articleData?.isEditable === true && (
                            <DenovoEditHistoryComponent
                              JCID={jcDetails?.JCID}
                              PMID={articleData?.PMID}
                            />
                          )}
                        {articleData?.isEditable === false && (
                          <>
                            {articleData.proposedBy &&
                              isContributor(jcUserDetails.role) && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                  mr={2}
                                >
                                  <Typography
                                    variant="BODY_COPY2_RALEWAY"
                                    sx={{
                                      display: "inline-block",
                                      marginRight: "5px",
                                    }}
                                  >
                                    Proposed by
                                  </Typography>
                                  <Avatar
                                    variant="square"
                                    alt="Benjamin Scricia"
                                    src={""}
                                    sx={{
                                      height: "38px",
                                      width: "38px",
                                      borderRadius: "2px!important",
                                    }}
                                  ></Avatar>
                                  <Typography
                                    variant="BODY_COPY2_RALEWAY"
                                    sx={{
                                      display: "inline-block",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {articleData.proposedBy.firstName +
                                      " " +
                                      articleData.proposedBy.lastName}
                                  </Typography>
                                </Box>
                              )}
                          </>
                        )}
                      </Box>
                    </Hidden>
                    <Hidden mdUp>
                      {
                        (
                          articleData?.isDeNovo === true &&
                          articleData.status !== "Published" &&
                          articleData.status !== "Rejected" &&
                          articleData?.isEditable === true
                        ) && (
                          <>
                            <DeNovoArticleStatus
                              currentUserEditingStatus={denovoArticleInEditMode}
                              hideEditButton={false}
                            />
                            <Spacer height={"7px"} />
                            <DenovoEditHistoryComponent
                              JCID={jcDetails?.JCID}
                              PMID={articleData?.PMID}
                            />
                          </>
                        )}

                      {articleData?.isEditable === false &&
                        isJCPage &&
                        isContributor(jcUserDetails.role) &&
                        articleData.proposedBy && (
                          <Box
                            mt={4}
                            pt={1}
                            pb={1}
                            sx={{ backgroundColor: "#F3F3F3" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                                alignItems: "center",
                              }}
                              mx={{ xs: 2, md: 0 }}
                            >
                              <Box sx={{ display: "inline-block" }} mr={1}>
                                <Typography variant="BODY_COPY1_RALEWAY">
                                  Article Proposed by
                                </Typography>
                              </Box>
                              <Box sx={{ display: "inline-block" }} mr={1}>
                                <Avatar
                                  variant="square"
                                  alt="Benjamin Scricia"
                                  src={""}
                                  sx={{
                                    height: "32px",
                                    width: "32px",
                                    borderRadius: "2px!important",
                                  }}
                                ></Avatar>
                              </Box>
                              <Box sx={{ display: "inline-block" }}>
                                <Typography variant="BODY_COPY1_RALEWAY">
                                  {articleData.proposedBy.firstName +
                                    " " +
                                    articleData.proposedBy.lastName}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                    </Hidden>
                  </Item>
                )}
                {/*Journal Club Active */}
                {isJCPage &&
                  isContributor(jcUserDetails.role) &&
                  articleData.journalClubs &&
                  articleData.journalClubs.filter(
                    (jc) => jc.articleStatus != "Rejected"
                  ).length > 0 && (
                    <ActiveJC
                      journalClubsList={articleData.journalClubs.filter(
                        (jc) => jc.articleStatus != "Rejected"
                      )}
                      PMID={articleData.PMID}
                    />
                  )}
                {isJCPage &&
                  !isContributor(jcUserDetails.role) &&
                  articleData.journalClubs &&
                  articleData.journalClubs.filter(
                    (jc) => jc.articleStatus === "Published"
                  ).length > 0 && (
                    <ActiveJC
                      journalClubsList={articleData.journalClubs.filter(
                        (jc) => jc.articleStatus === "Published"
                      )}
                      PMID={articleData.PMID}
                    />
                  )}
                {/* Main article*/}

                <Item sx={{ marginBottom: "16px" }}>
                  <Box px={{ xs: 0, sm: 0, md: 3 }} pt={2} mx={{ xs: 2, md: 0 }}>
                    {articleData.sourceName && (
                      <Typography variant="BODY_COPY1_RALEWAY" color="#565656">
                        {articleData.sourceName}
                        {articleData.issue && (
                          <Typography variant="BODY_COPY2_RALEWAY">
                            {" (" + articleData.issue + ")"}
                          </Typography>
                        )}
                      </Typography>
                    )}

                    <Typography
                      variant="H1_BITTER"
                      component="h1"
                      mt={{ xs: 0.5, md: 1 }}
                    >
                      {articleData.title}
                    </Typography>

                    <Hidden mdDown>
                      {articleData.statusUpdatedOn && isJCPage && (
                        <Typography
                          variant="BODY_COPY2_RALEWAY"
                          color="#838383"
                          sx={{ display: "block" }}
                          align="right"
                          mt={1}
                          pb={1.5}
                        >
                          {articleData?.status === "Under Discussion"
                            ? (articleData?.isDeNovo === true && articleData?.isEditable === true) ? "Started" : "Proposed"
                            : articleData.status}
                          {" " +
                            moment
                              .utc(articleData.statusUpdatedOn)
                              .local()
                              .fromNow()}
                        </Typography>
                      )}
                      {articleData.publicationdate && !isJCPage && (
                        <Typography
                          variant="BODY_COPY2_RALEWAY"
                          color="#838383"
                          sx={{ display: "block" }}
                          align="right"
                          mt={0.5}
                          pb={1.5}
                        >
                          {"Published " +
                            moment(
                              articleData.publicationdate,
                              "MM/DD/YYYY"
                            ).fromNow()}
                        </Typography>
                      )}
                    </Hidden>

                    <Hidden mdUp>
                      <Box pt={2} pb={2}>
                        <Box sx={{ display: "inline-block" }} >
                          {articleData && !articleData.isDeNovo &&
                            <Button
                              target="_blank"
                              href={articleData.url}
                              variant="contained"
                              size="small"
                              color="primary"
                              className="mobile-botton"
                              onClick={handleUrlClick}
                            >
                              View Article
                            </Button>
                          }
                          {articleData?.isDeNovo === true && articleData.status != "Published" &&
                            articleData.status != "Rejected" &&
                            isJCPage &&
                            isAdmin(jcUserDetails.role) && (
                              <Box mt={1}>
                                <Box
                                  pb={2}
                                  sx={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                  }}
                                  mr={2}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    className="mobile-botton"
                                    onClick={handlePublishClickOpen}
                                  >
                                    Publish Article
                                  </Button>
                                </Box>
                                <Box
                                  sx={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleRejectClickOpen}
                                  mt={1}
                                >
                                  <Link
                                    variant="BODY_COPY1_RALEWAY"
                                    color="#EF5B50"
                                  >
                                    <Typography
                                      variant="BODY_COPY2_RALEWAY"
                                      sx={{ fontWeight: "700!important" }}
                                    >
                                      Reject Article
                                    </Typography>
                                  </Link>
                                </Box>
                              </Box>
                            )}
                        </Box>
                        {!articleData?.isEditable &&
                          <Box sx={{ display: "inline-block", float: "right" }}>
                            <Box
                              sx={{ display: "inline-block" }}
                              onClick={() =>
                                dispatch(
                                  setSaved(
                                    articleData.PMID,
                                    userEmail,
                                    "J00000000",
                                    articleData.savedByUser
                                  )
                                )
                              }
                            >
                              <SaveIcon
                                className="mobile-paper-icon"
                                fillcolor={
                                  articleData.savedByUser ? "#EF5B50" : "None"
                                }
                              />
                            </Box>
                            <Box
                              sx={{ display: "inline-block" }}
                              onClick={handleOnShareClick}
                              ml={0.6}
                            >
                              <ShareIcon className="mobile-paper-icon" />
                            </Box>
                          </Box>
                        }
                      </Box>
                    </Hidden>

                    <Divider />

                    <Hidden mdUp>
                      {articleData.statusUpdatedOn && isJCPage && (
                        <Typography
                          variant="BODY_COPY2_RALEWAY"
                          color="#838383"
                          sx={{ display: "block" }}
                          align="left"
                          pt={1}
                        >
                          {articleData.status === "Under Discussion"
                            ? (articleData?.isDeNovo === true && articleData?.isEditable === true) ? "Started" : "Proposed"
                            : articleData.status}
                          {" " +
                            moment
                              .utc(articleData.statusUpdatedOn)
                              .local()
                              .fromNow()}
                        </Typography>
                      )}
                      {articleData.publicationdate && !isJCPage && (
                        <Typography
                          variant="BODY_COPY2_RALEWAY"
                          color="#838383"
                          sx={{ display: "block" }}
                          align="left"
                          pt={1}
                        >
                          {"Published " +
                            moment(
                              articleData.publicationdate,
                              "MM/DD/YYYY"
                            ).fromNow()}
                        </Typography>
                      )}
                    </Hidden>

                    {articleData.authors && (
                      <Box
                        pt={{ xs: 1.5, md: 2 }}
                        pb={1}
                        sx={{ lineHeight: "14px" }}
                      >
                        <Typography
                          variant="H3_RALEWAY"
                          sx={{ color: { xs: "#262626", md: "#838383" } }}
                        >
                          {articleData.authors.join(", ")}
                        </Typography>
                      </Box>
                    )}

                    {/*Note : Keywords are hidden */}
                    {/** 
                         {articleData.keywords &&
                        <Box pb={2} pt={1} sx={{marginLeft:'-5px'}}>
                          {articleData.keywords.map((keyword,idx) =>
                              <KeywordChip label={keyword} key={idx}></KeywordChip>
                          )}
                        </Box>
                        }
                    */}
                  </Box>
                  <Hidden mdDown>
                    {articleData.journalClubs &&
                      jcList &&
                      jcList.length != 0 &&
                      articleData.journalClubs.filter(
                        (JC) => JC.articleStatus === "Published"
                      ).length > 0 && (
                        <Box
                          px={{ xs: 0, sm: 0, md: 3 }}
                          pb={0.5}
                          mx={{ xs: 2, md: 0 }}
                          sx={{ marginLeft: "-5px!important" }}
                        >
                          {articleData.journalClubs
                            .filter((JC) => JC.articleStatus === "Published")
                            .map((jc, idx) => (
                              <JcChip
                                jcName={jc.name}
                                jcColor={getJCIconColor(jc.JCID)}
                                bgColor="#FFFFFF"
                                key={idx}
                              />
                            ))}
                          {articleData.numberOfJournalClubs -
                            articleData.journalClubs.length >
                            0 && (
                              <Typography variant="BODY_COPY2_RALEWAY">
                                {"+" +
                                  (articleData.numberOfJournalClubs -
                                    articleData.journalClubs.length) +
                                  " more"}
                              </Typography>
                            )}
                        </Box>
                      )}
                  </Hidden>
                  <Hidden mdUp>
                    {articleData.journalClubs &&
                      jcList &&
                      jcList.length != 0 &&
                      articleData.journalClubs.filter(
                        (JC) => JC.articleStatus === "Published"
                      ).length > 0 && (
                        <React.Fragment>
                          <Box mx={{ xs: 2, md: 0 }}>
                            <Divider />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100vw",
                              height: "40px",
                              overflowX: "auto",
                              "&::-webkit-scrollbar": { height: 0 },
                              backgroundColor: "#F3F3F3",
                            }}
                            mt={2}
                          >
                            {articleData.journalClubs
                              .filter((JC) => JC.articleStatus === "Published")
                              .map((jc, idx) => (
                                <JcChip
                                  jcName={jc.name}
                                  jcColor={getJCIconColor(jc.JCID)}
                                  bgColor="#F3F3F3"
                                  key={idx}
                                />
                              ))}
                            {articleData.numberOfJournalClubs -
                              articleData.journalClubs.length >
                              0 && (
                                <Typography variant="BODY_COPY2_RALEWAY">
                                  {"+" +
                                    (articleData.numberOfJournalClubs -
                                      articleData.journalClubs.length) +
                                    " more"}
                                </Typography>
                              )}
                          </Box>
                        </React.Fragment>
                      )}
                  </Hidden>

                  <Box
                    px={{ xs: 0, sm: 0, md: 3 }}
                    pt={{ xs: 2, md: 1 }}
                    mx={{ xs: 2, md: 0 }}
                  >
                    {articleData.authors && articleData.authors.length > 0 && (
                      <Divider />
                    )}

                    <Box pt={{ xs: 2, md: 3 }}>
                      <Box>
                        <Typography variant="H2_BITTER" gutterBottom>
                          {articleData.isDeNovo ? "SUMMARY" : "ABSTRACT" }
                        </Typography>
                      </Box>

                      {articleData.abstract && (
                        <Box mb={2} mt={{ xs: 1, md: 1.5 }}>
                          <Typography
                            variant="BODY_ARTICLE_RALEWAY"
                            gutterBottom
                            mb={2}
                          >
                            {articleData.abstract}
                          </Typography>
                        </Box>
                      )}
                      {articleData.abstractContent && (
                        <Box mb={2} mt={{ xs: 1, md: 1.5 }}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: convertToRender(
                                articleData.abstractContent,
                                articleData.abstractMedia
                              ),
                            }}
                            style={{ textAlign: "left", fontFamily: "Raleway" }}
                            className="devono-abstract"
                          ></p>
                        </Box>
                      )}

                      {articleData.bodyContent && (
                        <Box>
                          <Box pt={1} pb={1}>
                            <Divider />
                          </Box>
                          <Box mb={2} mt={{ xs: 1, md: 1.5 }}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: convertToRender(
                                  articleData.bodyContent,
                                  articleData.bodyMedia
                                ),
                              }}
                              style={{ textAlign: "left", fontFamily: "Raleway" }}
                              className="devono-abstract"
                            ></p>
                          </Box>
                        </Box>
                      )}

                      <Hidden mdDown>
                        <Box pb={2} pt={1}>
                          {articleData && !articleData.isDeNovo &&
                            <Box sx={{ display: "inline-block", cursor: "pointer" }} mr={2} mb={1}>
                              <Button
                                target="_blank"
                                href={articleData.url}
                                variant="contained"
                                color="primary"
                                sx={{ fontFamily: "Raleway", fontWeight: "700" }}
                                onClick={handleUrlClick}
                              >
                                VIEW ARTICLE
                              </Button>
                            </Box>
                          }
                          {articleData.status !== "Published" &&
                            articleData.status !== "Rejected" &&
                            isJCPage &&
                            isAdmin(jcUserDetails.role) && (
                              <Box
                                pb={2}
                                sx={{ display: "inline-block", cursor: "pointer" }}
                                mr={2}
                              >
                                <Button
                                  target="_blank"
                                  variant="outlined"
                                  color="primary"
                                  sx={{ fontFamily: "Raleway", fontWeight: "700" }}
                                  onClick={handlePublishClickOpen}
                                >
                                  PUBLISH ARTICLE
                                </Button>
                              </Box>
                            )}
                          {articleData.status !== "Published" &&
                            articleData.status !== "Rejected" &&
                            isJCPage &&
                            isAdmin(jcUserDetails.role) &&
                            <Box
                              sx={{ display: "inline-block", cursor: "pointer" }}
                              onClick={handleRejectClickOpen}
                              mt={1}
                            >
                              <Link variant="BODY_COPY1_RALEWAY" color="#EF5B50">
                                <Typography
                                  variant="BODY_COPY2_RALEWAY"
                                  sx={{ fontWeight: "700!important" }}
                                >
                                  Reject Article
                                </Typography>
                              </Link>
                            </Box>
                          }
                        </Box>
                      </Hidden>

                      <Hidden mdUp>
                        <Box pt={1} pb={2}>
                          <Box
                            sx={{ display: "inline-block", cursor: "pointer" }}
                            mr={2}
                          >
                            {articleData && !articleData.isDeNovo &&
                              <Button
                                target="_blank"
                                href={articleData.url}
                                variant="contained"
                                size="small"
                                color="primary"
                                className="mobile-botton"
                                onClick={handleUrlClick}
                              >
                                View Article
                              </Button>
                            }
                          </Box>
                          {articleData.status != "Published" &&
                            articleData.status != "Rejected" &&
                            isJCPage &&
                            isAdmin(jcUserDetails.role) && (
                              <Box mt={1}>
                                <Box
                                  pb={2}
                                  sx={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                  }}
                                  mr={2}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    className="mobile-botton"
                                    onClick={handlePublishClickOpen}
                                  >
                                    Publish Article
                                  </Button>
                                </Box>
                                <Box
                                  sx={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleRejectClickOpen}
                                  mt={1}
                                >
                                  <Link
                                    variant="BODY_COPY1_RALEWAY"
                                    color="#EF5B50"
                                  >
                                    <Typography
                                      variant="BODY_COPY2_RALEWAY"
                                      sx={{ fontWeight: "700!important" }}
                                    >
                                      Reject Article
                                    </Typography>
                                  </Link>
                                </Box>
                              </Box>
                            )}
                        </Box>
                      </Hidden>
                    </Box>

                    <PublishReject
                      open={confirmOpen}
                      onClose={handlePublishClose}
                      isReject={rejectModel}
                    />
                  </Box>
                </Item>

                {/* Support the article Section */}
                {isJCPage && isContributor(jcUserDetails.role) && <SuppotArticle />}
                {/* Support the article Ends */}

                {/* Comment and notes section */}
                <CommentNotes />
                {/* Comment and notes section Ends */}
              </Grid>
            )}

          {/* Denovo Layout */}
          {
            (
              articleData?.isDeNovo === true &&
              denovoArticleInEditMode === true &&
              articleData?.isEditable === true
            ) && (
              <Grid
                item
                xs={12}
                md={9}
                sx={{
                  minWidth: { xs: "100vw", md: "50vw", xl: "40vw" },
                }}
              >
                <ArticleDenovoView
                  PMID={articleData?.PMID}
                  JCID={jcDetails?.JCID}
                  journalClubName={jcDetails?.name}
                  articleTitle={articleData?.title}
                  articleAbstract={articleData?.abstractContent}
                  articleAuthors={articleData?.authors}
                  articleBody={articleData?.bodyContent}
                  bodyMedia={articleData?.bodyMedia}
                  abstractMedia={articleData?.abstractMedia}
                  articleCmeCredits={articleData?.cmeCredits}
                />
              </Grid>
            )}
          {/* Side bar*/}
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              md={2.7}
              className="rightnav"
              style={{ top: "64" }}
              sx={{
                marginLeft: "11px",
                positon: "sticky",
                flexGrow: "1",
                flexBasis: "300px",
                position: "sticky",
                // top: '64px',
                alignSelf: "start",
                // maxHeight: '100vh',
                overflow: "scroll",
                paddingBottom: "30px",
                msOverflowStyle: "none",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              <Grid container direction="column" spacing={1}>
                {/* Denovo Article Status */}

                <Hidden mdDown>
                  {articleData?.isDeNovo === true &&
                    articleData.status !== "Published" &&
                    articleData.status !== "Rejected" &&
                    articleData?.isEditable === true && (
                      <DeNovoArticleStatus
                        currentUserEditingStatus={denovoArticleInEditMode}
                      />
                    )}
                </Hidden>

                {articleData && !articleData.isDeNovo && (
                  <Grid item sx={{ maxHeight: "80px" }}>
                    <Item sx={{ textAlign: "center" }}>
                      <Box pt={1} pb={1}>
                        <Button
                          variant="contained"
                          sx={{
                            fontFamily: "Raleway",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                          onClick={handleUrlClick}
                        >
                          VIEW ARTICLE
                        </Button>
                      </Box>
                    </Item>
                  </Grid>
                )}

                {isJCPage && articleData && !articleData.isDeNovo &&
                  articleData.status !== "Published" &&
                  articleData.status !== "Rejected" &&
                  isAdmin(jcUserDetails.role) && (
                    <Grid item sx={{ maxHeight: "104px" }}>
                      <Item sx={{ textAlign: "center" }}>
                        <Box
                          pt={1}
                          pb={1}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              fontFamily: "Raleway",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                            onClick={handlePublishClickOpen}
                          >
                            PUBLISH ARTICLE
                          </Button>
                          <Box
                            sx={{ display: "inline-block", cursor: "pointer" }}
                            onClick={handleRejectClickOpen}
                            mt={1}
                          >
                            <Link variant="BODY_COPY1_RALEWAY" color="#EF5B50">
                              <Typography
                                variant="BODY_COPY2_RALEWAY"
                                sx={{ fontWeight: "700!important" }}
                              >
                                Reject Article
                              </Typography>
                            </Link>
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                  )}
                {
                  articleData?.isDeNovo === true &&
                  isAdmin(jcUserDetails.role) &&
                  (denovoArticleInEditMode === false || denovoArticleInEditMode === null) &&
                  articleData.status !== "Published" &&
                  articleData.status !== "Rejected" && (
                    <Grid item sx={{ maxHeight: "104px" }}>
                      <Item sx={{ textAlign: "center" }}>
                        <Box
                          pt={1}
                          pb={1}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              fontFamily: "Raleway",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                            onClick={handlePublishClickOpen}
                          >
                            PUBLISH ARTICLE
                          </Button>
                          <Box
                            sx={{ display: "inline-block", cursor: "pointer" }}
                            onClick={handleRejectClickOpen}
                            mt={1}
                          >
                            <Link variant="BODY_COPY1_RALEWAY" color="#EF5B50">
                              <Typography
                                variant="BODY_COPY2_RALEWAY"
                                sx={{ fontWeight: "700!important" }}
                              >
                                Reject Article
                              </Typography>
                            </Link>
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                  )}

                {/* Discussion Actions */}
                {isJCPage && isContributor(jcUserDetails.role) && (
                  <DiscussionActions
                    isDisabled={
                      (
                        (articleData?.isDeNovo && denovoArticleInEditMode)
                      )
                        ? true
                        : false
                    }
                  />
                )}

                {/* Engagement */}
                <EngagementComponent
                  isDisabled={
                    (
                      (articleData?.isDeNovo && denovoArticleInEditMode)
                    )
                      ? true
                      : false
                  }
                />
                {/* Actions */}
                <ActionComponent
                  isJCPage={isJCPage}
                  publishJCIds={publishJCIds}
                  isDisabled={
                    (
                      (articleData?.isDeNovo && denovoArticleInEditMode) ||
                      (
                        articleData?.isDeNovo && (
                          articleData?.status === DENOVO_ARTICLE_STATUS.DRAFT &&
                          articleData?.isEditable === true
                        )
                      )

                    )
                      ? true
                      : false
                  }
                />
              </Grid>
            </Grid>
          </Hidden>

          {/* side bar ends */}

          <div>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={toastUrlMsg}
              autoHideDuration={2000}
              onClose={handleUrlClose}
              key={"top" + "center"}
            >
              <SnackbarContent
                sx={{
                  backgroundColor: "#262626",
                  fontFamily: "Raleway",
                  fontSize: mobileMatch ? "12px" : "14px",
                  padding: "0",
                  paddingLeft: mobileMatch ? "10px" : "20px",
                  paddingRight: mobileMatch ? "10px" : "20px",
                }}
                message={
                  "This article is not accessible on " + articleData.sourceName
                }
              />
            </Snackbar>
          </div>
        </Grid>


      </Box>
    );


  }











}

export default ArticleDetailedView;

const _isUserRoleContributorAndAbove = (userRole) => {
  if (
    userRole === USER_ROLES.ADMINISTRATOR ||
    userRole === USER_ROLES.COURSE_DIRECTOR ||
    userRole === USER_ROLES.CONTRIBUTOR
  ) {
    return true;
  } else {
    return false;
  }
}




