export const BoldIcon = `
    <svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.25 12.7312C18.0688 11.475 19.3438 9.4125 19.3438 7.5C19.3438 3.2625 16.0625 0 11.8438 0H0.125V26.25H13.325C17.2437 26.25 20.2812 23.0625 20.2812 19.1437C20.2812 16.2938 18.6688 13.8562 16.25 12.7312ZM5.75 4.6875H11.375C12.9312 4.6875 14.1875 5.94375 14.1875 7.5C14.1875 9.05625 12.9312 10.3125 11.375 10.3125H5.75V4.6875ZM12.3125 21.5625H5.75V15.9375H12.3125C13.8687 15.9375 15.125 17.1938 15.125 18.75C15.125 20.3062 13.8687 21.5625 12.3125 21.5625Z" fill="currentcolor"/>
    </svg>`;

export const Header1 = `
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V5.2H8.46154V26H13.5385V5.2H22V0H0Z" fill="currentcolor"/>
    </svg>`;
export const Header2 = `
    <svg width="18" height="17" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H15V5.1H10V17H5V5.1H0V0Z" fill="currentcolor"/>
    </svg>`;

export const Superscript = `
    <svg width="26" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.96822 0.8H8.82389L8.77841 0.936981L0.810188 24.937L0.722863 25.2H1H4.08754H4.23389L4.27817 25.0605L6.6245 17.6686H14.6007L17.0454 25.0628L17.0907 25.2H17.2352H20.4226H20.7052L20.6112 24.9335L12.145 0.933466L12.0979 0.8H11.9564H8.96822ZM11.6666 8.19256L13.8705 14.8588L7.31921 14.8585L9.5563 8.09192L9.55665 8.09204L9.55976 8.0803C9.90444 6.77755 10.2489 5.54976 10.5939 4.3311C10.8771 5.55578 11.2347 6.79892 11.6655 8.18899L11.6654 8.189L11.6666 8.19256ZM22.9026 1.03588L22.8564 0.900419H22.7133H21.3187H21.1742L21.1288 1.03763L17.9414 10.6778L17.8545 10.9406H18.1313H19.4264H19.5709L19.6163 10.8034L20.5669 7.92803H23.5647L24.5153 10.8034L24.5607 10.9406H24.7052H26H26.2795L26.1893 10.676L22.9026 1.03588ZM22.3244 3.87457L23.1372 6.42343H20.8954L21.7082 3.8746C21.7083 3.8743 21.7084 3.87401 21.7085 3.87371C21.7988 3.60041 21.8692 3.3271 21.9354 3.06992C21.9439 3.03655 21.9525 3.00346 21.9609 2.97067C21.9704 2.934 21.9799 2.89772 21.9893 2.86184C22.0553 3.08448 22.1299 3.30234 22.2061 3.52505C22.2454 3.63953 22.285 3.75529 22.3241 3.87365C22.3242 3.87395 22.3243 3.87426 22.3244 3.87457Z" fill="currentcolor" stroke="currentcolor" stroke-width="0.4"/>
    </svg>`;

export const Subscript = `
    <svg width="26" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.2051 0.8H9.06211L9.01585 0.935301L0.810754 24.9353L0.720259 25.2H1H4.17933H4.32437L4.36943 25.0621L6.78605 17.6686H15.0106L17.5286 25.0645L17.5747 25.2H17.7179H21H21.2854L21.188 24.9317L12.4701 0.931716L12.4222 0.8H12.2821H9.2051ZM11.9899 8.19423L14.2588 14.8588L7.50143 14.8585L9.80446 8.0936L9.80483 8.09372L9.8081 8.08172C10.1653 6.77073 10.5222 5.53565 10.8798 4.30937C11.1722 5.54166 11.5423 6.79178 11.9887 8.19059L11.9887 8.1906L11.9899 8.19423Z" fill="currentcolor" stroke="currentcolor" stroke-width="0.4"/>
        <path d="M26.282 14.8H26.1372L26.092 14.9376L22.81 24.9376L22.7239 25.2H23H24.2717H24.4186L24.4626 25.0599L25.4033 22.0619H28.5165L29.4971 25.0621L29.5421 25.2H29.6872H31H31.2816L31.1888 24.9341L27.7017 14.9341L27.6549 14.8H27.5128H26.282ZM27.2816 18.0329L28.1395 20.6578L25.7646 20.6577L26.6362 17.9909L26.6365 17.9911L26.6395 17.9794C26.741 17.5914 26.8425 17.2194 26.944 16.853C27.0407 17.2276 27.153 17.6132 27.2805 18.0293L27.2804 18.0293L27.2816 18.0329Z" fill="currentcolor" stroke="currentcolor" stroke-width="0.4"/>
    </svg>`;

export const Outdent = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.75 18.25H23.25V15.75H10.75V18.25ZM0.75 12L5.75 17V7L0.75 12ZM0.75 23.25H23.25V20.75H0.75V23.25ZM0.75 0.75V3.25H23.25V0.75H0.75ZM10.75 8.25H23.25V5.75H10.75V8.25ZM10.75 13.25H23.25V10.75H10.75V13.25Z" fill="currentcolor"/>
    </svg>`;

export const Indent = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 23.25H23.25V20.75H0.75V23.25ZM0.75 7V17L5.75 12L0.75 7ZM10.75 18.25H23.25V15.75H10.75V18.25ZM0.75 0.75V3.25H23.25V0.75H0.75ZM10.75 8.25H23.25V5.75H10.75V8.25ZM10.75 13.25H23.25V10.75H10.75V13.25Z" fill="currentcolor"/>
    </svg>`;

export const PdfIcon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="45px" height="45px" fill-rule="nonzero">
<g fill="currentcolor" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
<g transform="scale(5.12,5.12)"><path d="M30.39844,2h-23.39844v46h36v-33.39844zM15,28h16v2h-16zM35,36h-20v-2h20zM35,24h-20v-2h20zM30,15v-10.60156l10.60156,10.60156z">
</path>
</g>
</g>
</svg>`;

export const VideoIcon = `
    <svg width="24" height="19" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.2083 7.1875V1.79167C22.2083 0.94375 21.5146 0.25 20.6667 0.25H2.16667C1.31875 0.25 0.625 0.94375 0.625 1.79167V17.2083C0.625 18.0562 1.31875 18.75 2.16667 18.75H20.6667C21.5146 18.75 22.2083 18.0562 22.2083 17.2083V11.8125L28.375 17.9792V1.02083L22.2083 7.1875Z" fill="currentcolor"/>
    </svg>`;

export const ImageIcon = `
    <svg width="24" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3333 17.6C15.6897 17.6 17.6 15.6897 17.6 13.3333C17.6 10.9769 15.6897 9.06667 13.3333 9.06667C10.9769 9.06667 9.06667 10.9769 9.06667 13.3333C9.06667 15.6897 10.9769 17.6 13.3333 17.6Z" fill="#838383"/>
        <path d="M9.33333 0L6.89333 2.66667H2.66667C1.2 2.66667 0 3.86667 0 5.33333V21.3333C0 22.8 1.2 24 2.66667 24H24C25.4667 24 26.6667 22.8 26.6667 21.3333V5.33333C26.6667 3.86667 25.4667 2.66667 24 2.66667H19.7733L17.3333 0H9.33333ZM13.3333 20C9.65333 20 6.66667 17.0133 6.66667 13.3333C6.66667 9.65333 9.65333 6.66667 13.3333 6.66667C17.0133 6.66667 20 9.65333 20 13.3333C20 17.0133 17.0133 20 13.3333 20Z" fill="currentcolor"/>
    </svg>`;
export const LinkIcon = `
<svg width="24" height="18" viewBox="0 0 40 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.25 3.7H16.375V3.575V0.25V0.125H16.25H9.25C4.35096 0.125 0.375 4.10096 0.375 9C0.375 13.899 4.35096 17.875 9.25 17.875H16.25H16.375V17.75V14.425V14.3H16.25H9.25C6.32654 14.3 3.95 11.9235 3.95 9C3.95 6.07654 6.32654 3.7 9.25 3.7H16.25ZM10.875 10.75V10.875H11H25H25.125V10.75V7.25V7.125H25H11H10.875V7.25V10.75ZM19.75 0.125H19.625V0.25V3.575V3.7H19.75H26.75C29.6735 3.7 32.05 6.07654 32.05 9C32.05 11.9235 29.6735 14.3 26.75 14.3H19.75H19.625V14.425V17.75V17.875H19.75H26.75C31.649 17.875 35.625 13.899 35.625 9C35.625 4.10096 31.649 0.125 26.75 0.125H19.75Z" fill="currentcolor" stroke="currentcolor" stroke-width="0.25"/>
</svg>`;

export const ColorPickerIcon = `
<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="ql-color-label" d="M22.2575 4.53125L17.7262 0L13.1104 4.61583L10.7662 2.30792L9.0625 4.01167L10.7783 5.7275L0 16.5058V22.2454H5.73958L16.5179 11.4671L18.2337 13.1829L19.9375 11.4792L17.6175 9.15917L22.2575 4.53125ZM4.73667 19.8288L2.41667 17.5088L12.1558 7.76958L14.4758 10.0896L4.73667 19.8288Z" fill="currentcolor"/>
</svg>`;

export const NumberList = `
<svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.583496 16.9583H3.16683V17.6041H1.87516V18.8958H3.16683V19.5416H0.583496V20.8333H4.4585V15.6666H0.583496V16.9583ZM1.87516 5.33329H3.16683V0.166626H0.583496V1.45829H1.87516V5.33329ZM0.583496 9.20829H2.9085L0.583496 11.9208V13.0833H4.4585V11.7916H2.1335L4.4585 9.07913V7.91663H0.583496V9.20829ZM7.04183 1.45829V4.04163H25.1252V1.45829H7.04183ZM7.04183 19.5416H25.1252V16.9583H7.04183V19.5416ZM7.04183 11.7916H25.1252V9.20829H7.04183V11.7916Z" fill="currentcolor"/>
</svg>`;

export const DotList = `
<svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.3335 8C1.22683 8 0.333496 8.89333 0.333496 10C0.333496 11.1067 1.22683 12 2.3335 12C3.44016 12 4.3335 11.1067 4.3335 10C4.3335 8.89333 3.44016 8 2.3335 8ZM2.3335 0C1.22683 0 0.333496 0.893333 0.333496 2C0.333496 3.10667 1.22683 4 2.3335 4C3.44016 4 4.3335 3.10667 4.3335 2C4.3335 0.893333 3.44016 0 2.3335 0ZM2.3335 16C1.22683 16 0.333496 16.9067 0.333496 18C0.333496 19.0933 1.24016 20 2.3335 20C3.42683 20 4.3335 19.0933 4.3335 18C4.3335 16.9067 3.44016 16 2.3335 16ZM6.3335 19.3333H25.0002V16.6667H6.3335V19.3333ZM6.3335 11.3333H25.0002V8.66667H6.3335V11.3333ZM6.3335 0.666667V3.33333H25.0002V0.666667H6.3335Z" fill="currentcolor"/>
</svg>`;

export const UnderlineIcon = `
<svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0002 21.0833C15.6893 21.0833 19.5002 17.2725 19.5002 12.5833V1.25H15.9585V12.5833C15.9585 15.3175 13.7343 17.5417 11.0002 17.5417C8.266 17.5417 6.04183 15.3175 6.04183 12.5833V1.25H2.50016V12.5833C2.50016 17.2725 6.311 21.0833 11.0002 21.0833ZM1.0835 23.9167V26.75H20.9168V23.9167H1.0835Z" fill="currentcolor"/>
<path d="M2.00016 0.75H6.54183V12.5833C6.54183 15.0414 8.54214 17.0417 11.0002 17.0417C13.4582 17.0417 15.4585 15.0414 15.4585 12.5833V0.75H20.0002V12.5833C20.0002 17.5486 15.9655 21.5833 11.0002 21.5833C6.03485 21.5833 2.00016 17.5486 2.00016 12.5833V0.75ZM3.00016 1.75V12.5833C3.00016 16.9964 6.58714 20.5833 11.0002 20.5833C15.4132 20.5833 19.0002 16.9964 19.0002 12.5833V1.75H16.4585V12.5833C16.4585 15.5936 14.0105 18.0417 11.0002 18.0417C7.98985 18.0417 5.54183 15.5936 5.54183 12.5833V1.75H3.00016ZM0.583496 23.4167H21.4168V27.25H0.583496V23.4167ZM1.5835 24.4167V26.25H20.4168V24.4167H1.5835Z" fill="currentcolor"/>
</svg>`;

export const ItalicIcon = `
<svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.33333 0V5.57143H11.385L5.115 20.4286H0V26H14.6667V20.4286H10.615L16.885 5.57143H22V0H7.33333Z" fill="currentcolor"/>
</svg>`;

export const YoutubeIcon = `<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2352 2.59286C15.0558 1.92656 14.5304 1.40121 13.8641 1.22182C12.6468 0.888672 7.77774 0.888672 7.77774 0.888672C7.77774 0.888672 2.90865 0.888672 1.69137 1.20901C1.03789 1.3884 0.499723 1.92656 0.320335 2.59286C0 3.81013 0 6.33437 0 6.33437C0 6.33437 0 8.87143 0.320335 10.0759C0.499723 10.7422 1.02507 11.2675 1.69137 11.4469C2.92146 11.7801 7.77774 11.7801 7.77774 11.7801C7.77774 11.7801 12.6468 11.7801 13.8641 11.4597C14.5304 11.2804 15.0558 10.755 15.2352 10.0887C15.5555 8.87143 15.5555 6.34719 15.5555 6.34719C15.5555 6.34719 15.5683 3.81013 15.2352 2.59286Z" fill="currentcolor"/>
<path d="M6.22729 4.00293V8.66701L10.2763 6.33497L6.22729 4.00293Z" fill="white"/>
<path d="M3.41684 17.6584C3.28754 17.5722 3.19596 17.4375 3.14209 17.249C3.08822 17.0658 3.06128 16.818 3.06128 16.5163V16.1015C3.06128 15.7944 3.0936 15.5466 3.15286 15.3581C3.21751 15.1695 3.31448 15.0348 3.44377 14.9486C3.57845 14.8624 3.75084 14.8193 3.96633 14.8193C4.17643 14.8193 4.34882 14.8624 4.47811 14.9486C4.60741 15.0348 4.69899 15.1749 4.75825 15.3581C4.81751 15.5412 4.84983 15.789 4.84983 16.1015V16.5163C4.84983 16.8234 4.8229 17.0658 4.76364 17.2544C4.70438 17.4375 4.61279 17.5776 4.4835 17.6638C4.35421 17.75 4.17643 17.7931 3.95556 17.7931C3.72929 17.7877 3.54613 17.7446 3.41684 17.6584ZM4.14411 17.2059C4.18182 17.1143 4.19798 16.9581 4.19798 16.748V15.8537C4.19798 15.649 4.18182 15.4981 4.14411 15.4012C4.1064 15.3042 4.04714 15.2611 3.95556 15.2611C3.86936 15.2611 3.80471 15.3096 3.77239 15.4012C3.73468 15.4981 3.71852 15.649 3.71852 15.8537V16.748C3.71852 16.9581 3.73468 17.1143 3.77239 17.2059C3.80471 17.2975 3.86936 17.3459 3.96094 17.3459C4.04714 17.3459 4.1064 17.3028 4.14411 17.2059Z" fill="currentcolor"/>
<path d="M13.3615 16.5207V16.6662C13.3615 16.8494 13.3669 16.9894 13.3777 17.081C13.3884 17.1726 13.41 17.2426 13.4477 17.2803C13.48 17.3234 13.5339 17.345 13.6093 17.345C13.7063 17.345 13.7709 17.3073 13.8086 17.2319C13.8463 17.1564 13.8625 17.0325 13.8679 16.8548L14.4228 16.8871C14.4282 16.914 14.4282 16.9463 14.4282 16.9894C14.4282 17.2534 14.3581 17.4527 14.2127 17.582C14.0672 17.7113 13.8625 17.776 13.5985 17.776C13.2807 17.776 13.0598 17.6736 12.9305 17.4743C12.8012 17.275 12.7366 16.9679 12.7366 16.5477V16.0467C12.7366 15.6157 12.8012 15.3032 12.9359 15.1039C13.0706 14.9046 13.2968 14.8076 13.6147 14.8076C13.8356 14.8076 14.008 14.8507 14.1265 14.9315C14.245 15.0123 14.3258 15.1416 14.3743 15.3086C14.4228 15.481 14.4443 15.7181 14.4443 16.0197V16.51H13.3615V16.5207ZM13.4423 15.314C13.41 15.3571 13.3884 15.4218 13.3777 15.5133C13.3669 15.6049 13.3615 15.745 13.3615 15.9335V16.1383H13.8356V15.9335C13.8356 15.7504 13.8302 15.6103 13.8194 15.5133C13.8086 15.4164 13.7817 15.3517 13.7494 15.314C13.717 15.2763 13.6632 15.2548 13.5985 15.2548C13.5231 15.2494 13.4746 15.2709 13.4423 15.314Z" fill="currentcolor"/>
<path d="M1.84374 16.4674L1.11108 13.8115H1.75216L2.01075 15.0129C2.07539 15.3092 2.12388 15.5624 2.1562 15.7725H2.17236C2.19391 15.6216 2.2424 15.3738 2.31782 15.0183L2.58718 13.8115H3.22826L2.48482 16.4674V17.7388H1.84913V16.4674H1.84374Z" fill="currentcolor"/>
<path d="M6.93468 14.873V17.7444H6.42828L6.37441 17.3943H6.35825C6.22357 17.6582 6.01347 17.7929 5.73872 17.7929C5.55017 17.7929 5.4101 17.7283 5.31852 17.6044C5.22694 17.4805 5.18384 17.2865 5.18384 17.0172V14.873H5.8303V16.9794C5.8303 17.1087 5.84646 17.2003 5.8734 17.2542C5.90034 17.3081 5.94882 17.335 6.01347 17.335C6.06734 17.335 6.12121 17.3188 6.17508 17.2811C6.22896 17.2488 6.26667 17.2057 6.28822 17.1518V14.873H6.93468Z" fill="currentcolor"/>
<path d="M10.2477 14.873V17.7444H9.74127L9.6874 17.3943H9.67124C9.53656 17.6582 9.32646 17.7929 9.05171 17.7929C8.86316 17.7929 8.72309 17.7283 8.63151 17.6044C8.53992 17.4805 8.49683 17.2865 8.49683 17.0172V14.873H9.14329V16.9794C9.14329 17.1087 9.15945 17.2003 9.18639 17.2542C9.21332 17.3081 9.26181 17.335 9.32646 17.335C9.38033 17.335 9.4342 17.3188 9.48807 17.2811C9.54194 17.2488 9.57965 17.2057 9.6012 17.1518V14.873H10.2477Z" fill="currentcolor"/>
<path d="M8.69078 14.3341H8.0497V17.7388H7.4194V14.3341H6.77832V13.8115H8.69078V14.3341Z" fill="currentcolor"/>
<path d="M12.3866 15.3307C12.3489 15.1475 12.2842 15.0182 12.198 14.9374C12.1118 14.8566 11.9879 14.8135 11.8371 14.8135C11.7186 14.8135 11.6054 14.8458 11.5031 14.9158C11.4007 14.9805 11.3199 15.0721 11.266 15.1798H11.2607V13.666H10.6357V17.7387H11.1691L11.2337 17.4694H11.2499C11.2984 17.5664 11.3738 17.6418 11.4761 17.701C11.5785 17.7549 11.6862 17.7872 11.8102 17.7872C12.031 17.7872 12.1873 17.6849 12.2896 17.4855C12.392 17.2862 12.4458 16.9684 12.4458 16.5428V16.0903C12.4405 15.7616 12.4243 15.5084 12.3866 15.3307ZM11.794 16.4997C11.794 16.7098 11.7832 16.8714 11.7671 16.9899C11.7509 17.1084 11.7186 17.1946 11.6809 17.2431C11.6378 17.2916 11.5839 17.3185 11.5192 17.3185C11.4654 17.3185 11.4169 17.3078 11.3738 17.2808C11.3307 17.2539 11.293 17.2162 11.266 17.1677V15.5408C11.2876 15.4653 11.3253 15.4007 11.3792 15.3468C11.433 15.2983 11.4923 15.2714 11.5516 15.2714C11.6162 15.2714 11.6701 15.2983 11.7024 15.3468C11.7401 15.4007 11.7617 15.4869 11.7778 15.6054C11.794 15.7293 11.7994 15.9017 11.7994 16.1226V16.4997H11.794Z" fill="currentcolor"/>
</svg>`;
