import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import { Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import { LockIcon } from "../../assets";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import {
  openDenovoArticleInEditMode,
  closeDenovoArticleInEditMode,
  resetDenovoArticleInEditMode,
  getJournalClubArticle,
  setDenovoAutosaveTimestamp,
  setDirty,
  updateDenovoArticleToDB,
  autoSaveDenovoArticleToDB,
  acquireLockAndEditDenovoArticle,
  setDenovoLockState,
} from "../../state/actions/article";
import {
  convertImageArraytoPost,
  addWrapperToContent,
  uploadToS3FromUrl,
} from "../../utils/JCWysiwygEditor";

import * as api from "../../api";
import { DENOVO_AUTO_SAVE_TIME_IN_MINUTES } from "../../constants/constants";
import ErrorDialogBox from "./ErrorDialogBox";
import { useTheme } from "@mui/system";
import { useLocation } from "react-router";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  textAlign: "initial",
  paddingTop: "16px",
  paddingBottom: "16px",
}));

const StyledEditArticleButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.down("md")]: {},
}));

function DeNovoArticleStatus(props) {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;

  const dispatch = useDispatch();
  const { PMID } = useSelector((state) => state.article.articleData);

  const { jcDetails } = useSelector((state) => state.journalclub);
  const [disableSaveandDone, setDisableSaveandDone] = useState(false);
  const {
    articleData,
    denovoArticleInEditMode,
    denovoArticleData,
    denovoArticleAutoSaveTimeStamp,
    dirty,
    denovoEditHistory,
    errorInDenovoArticle,
    denovoLockState,
  } = useSelector((state) => state.article);

  const {
    isDenovoArticleLockedByCurrentUser,
    openLockStatusDialogBox,
    lockStatusMessage,
  } = denovoLockState;

  const [currentTimestamp, setCurrentTimestamp] = useState(
    moment().toISOString()
  );

  // const [
  //   isDenovoArticleLockedByCurrentUser,
  //   setIsDenovoArticleLockedByCurrentUser,
  // ] = useState(false);
  // const [openLockStatusDialogBox, setOpenLockStatusDialogBox] = useState(false);
  // const [lockStatusMessage, setLockStatusMessage] = useState("");

  useEffect(() => {
    if (errorInDenovoArticle.hasError === true) {
      dispatch(
        setDenovoLockState({
          isDenovoArticleLockedByCurrentUser: false,
          openLockStatusDialogBox: true,
          lockStatusMessage: errorInDenovoArticle.errorMessage,
          currentlyEditedBy: null,
        })
      );
      dispatch(closeDenovoArticleInEditMode());
    }
  }, [errorInDenovoArticle]);

  // useEffect(() => {
  //   if (
  //     checkErrorsInTitle(denovoArticleData.title) ||
  //     checkErrorsInAuthors(denovoArticleData.authors)
  //   ) {
  //     setDisableSaveandDone(true);
  //   } else {
  //     setDisableSaveandDone(false);
  //   }
  // }, [denovoArticleData]);

  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrentTimestamp(moment().toISOString());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  useEffect(() => {
    let oneMinuteInMilliseconds = 1000 * 60;
    let timeDifferenceInMilliseconds;
    if (denovoArticleAutoSaveTimeStamp === "") {
      timeDifferenceInMilliseconds =
        moment(currentTimestamp) -
        moment(
          denovoEditHistory?.editors?.length > 0
            ? denovoEditHistory?.editors[0].lastEdited
            : ""
        );
    } else {
      timeDifferenceInMilliseconds =
        moment(currentTimestamp) - moment(denovoArticleAutoSaveTimeStamp);
    }
    let timeDifferenceInMinutes =
      timeDifferenceInMilliseconds / oneMinuteInMilliseconds;

    if (
      timeDifferenceInMinutes >= DENOVO_AUTO_SAVE_TIME_IN_MINUTES &&
      dirty === true &&
      isDenovoArticleLockedByCurrentUser === true && false
    ) {
      dispatch(
        autoSaveDenovoArticleToDB({
          denovoArticleData,
          JCID: jcDetails?.JCID,
          PMID: articleData?.PMID,
          userEmail,
          articleData
        })
      );
    }
  }, [currentTimestamp]);

  const handleEditArticle = () => {
    dispatch(
      acquireLockAndEditDenovoArticle({
        JCID: jcDetails?.JCID,
        PMID: articleData?.PMID,
        userEmail,
        nextAction: () => { },
      })
    );
  };

  const handleSaveAndDone = () => {
    setDisableSaveandDone(true);
    dispatch(
      updateDenovoArticleToDB({
        denovoArticleData,
        JCID: jcDetails?.JCID,
        PMID,
        userEmail,
        articleData
      })
    );
  };

  const upoloadArrayOfFile = async (fileListToUpload, mediaObject) => {
    return new Promise((resolve, reject) => {
      fileListToUpload.map((uploadedFile) => {
        const currFile = mediaObject.filter((file) => file.fileName === uploadedFile.fileName)[0];
        console.log(currFile, ":: Current file being uploaded");
        console.log(uploadedFile, ":: upload file");

        uploadToS3FromUrl(currFile.url, uploadedFile, currFile.upload_data).then((result) => {
          resolve(result);
        });
      });
    });
  };

  return (
    <React.Fragment>
      <Hidden mdDown>
        {/* <p>{`CurrentTime: ${currentTimestamp}`}</p>
        <p>{`DenovoTime: ${denovoArticleAutoSaveTimeStamp}`}</p>
        <p>{`Difference: ${moment(
          denovoArticleAutoSaveTimeStamp
        ).fromNow()}`}</p>
        <p>
          {" "}
          {denovoEditHistory?.editors?.length > 0
            ? JSON.stringify(denovoEditHistory?.editors[0].lastEdited)
            : ""}
        </p>
        <p>{JSON.stringify(isDenovoArticleLockedByCurrentUser)}</p> */}

        <Grid item>
          <Item sx={{ textAlign: "center", maxHeight: "150px" }}>
            <Typography
              variant="BODY_ARTICLE_RALEWAY"
              sx={{ fontWeight: "700!important" }}
            >
              Status:
              <Typography
                variant="BODY_ARTICLE_RALEWAY"
                component="span"
                mx={0.5}
              >
                Draft
              </Typography>
            </Typography>
            {
              denovoLockState.currentlyEditedBy !== null && (
                <>
                  <Box>
                    <LockIcon width="18px" height="14px" />
                    <Typography variant="BODY_COPY2_RALEWAY" >
                      Currently being edited by
                    </Typography>
                  </Box>
                  <Typography variant="BODY_COPY2_RALEWAY" sx={{ fontWeight: '700!important' }}>
                    {
                      denovoLockState.currentlyEditedBy.userEmail === userEmail ?
                        "You" :
                        `${denovoLockState.currentlyEditedBy.firstName} ${denovoLockState.currentlyEditedBy.lastName}`
                    }
                  </Typography>
                </>
              )
            }


            <Box pt={1} pb={1}>
              {denovoArticleInEditMode && (
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Raleway",
                    fontWeight: "700",
                    fontSize: "14px",
                  }}
                  onClick={handleSaveAndDone}
                  disabled={disableSaveandDone}
                >
                  SAVE AND DONE
                </Button>
              )}
              {(denovoArticleInEditMode === false ||
                denovoArticleInEditMode === null) && (
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "Raleway",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                    onClick={handleEditArticle}
                  >
                    EDIT ARTICLE
                  </Button>
                )}
            </Box>

            <Box>
              {(denovoArticleInEditMode === false ||
                denovoArticleAutoSaveTimeStamp === "") && (
                  <Typography variant="BODY_COPY2_RALEWAY">
                    {`Last saved ${denovoEditHistory?.editors?.length > 0
                      ? moment
                        .utc(denovoEditHistory?.editors[0].lastEdited)
                        .local()
                        .fromNow()
                      : ""
                      }`}
                  </Typography>
                )}
              {denovoArticleInEditMode &&
                denovoArticleAutoSaveTimeStamp !== "" && (
                  <Typography variant="BODY_COPY2_RALEWAY">
                    {`Last auto-saved ${moment(
                      denovoArticleAutoSaveTimeStamp
                    ).fromNow()}`}
                  </Typography>
                )}
            </Box>
          </Item>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item>
          <Box
            sx={{
              backgroundColor: "#F3F3F3",
              padding: "10px 14px 10px 14px",
              margin: "32px 0px 0px 0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="BODY_ARTICLE_RALEWAY"
                sx={{ fontWeight: "700!important" }}
              >
                Status:
                <Typography
                  variant="BODY_ARTICLE_RALEWAY"
                  component="span"
                  mx={0.5}
                >
                  Draft
                </Typography>
              </Typography>
              {(denovoArticleInEditMode === false ||
                denovoArticleAutoSaveTimeStamp === "") && (
                  <Typography variant="BODY_COPY2_RALEWAY">
                    {`Last saved ${denovoEditHistory?.editors?.length > 0
                      ? moment
                        .utc(denovoEditHistory?.editors[0].lastEdited)
                        .local()
                        .fromNow()
                      : ""
                      }`}
                  </Typography>
                )}
              {denovoArticleInEditMode &&
                denovoArticleAutoSaveTimeStamp !== "" && (
                  <Typography variant="BODY_COPY2_RALEWAY">
                    {`Last auto-saved ${moment(
                      denovoArticleAutoSaveTimeStamp
                    ).fromNow()}`}
                  </Typography>
                )}
            </Box>
            {
              denovoLockState.currentlyEditedBy !== null && (
                <Box
                  sx={{
                    margin: '8px 0px 8px 0px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                >
                  <LockIcon width="18px" height="14px" />
                  <Typography variant="BODY_COPY2_RALEWAY" >
                    Currently being edited by &nbsp;
                  </Typography>
                  <Typography variant="BODY_COPY2_RALEWAY" sx={{ fontWeight: '700!important' }}>
                    {
                      denovoLockState.currentlyEditedBy.userEmail === userEmail ?
                        "You" :
                        `${denovoLockState.currentlyEditedBy.firstName} ${denovoLockState.currentlyEditedBy.lastName}`
                    }
                  </Typography>
                </Box>

              )
            }


            {props.hideEditButton === false && (
              <Box sx={{ marginTop: "10px" }}>
                {(denovoArticleInEditMode === false ||
                  denovoArticleInEditMode === null) && (
                    <Button
                      variant="contained"
                      size="small"
                      className="mobile-botton"
                      onClick={handleEditArticle}
                    >
                      Edit Article
                    </Button>
                  )}
              </Box>
            )}
          </Box>
        </Grid>
      </Hidden>
      <ErrorDialogBox
        open={openLockStatusDialogBox}
        closeDialogHandler={() => {
          dispatch(
            setDenovoLockState({
              openLockStatusDialogBox: false,
            })
          );
        }}
        title={errorInDenovoArticle.hasError ? "Error" : "Article Lock Status"}
      >
        <Typography
          sx={{
            ...theme.typography.H3_RALEWAY,
            fontWeight: 500,
            marginTop: "16px",
          }}
        >
          {lockStatusMessage}
        </Typography>
      </ErrorDialogBox>
    </React.Fragment>
  );
}

export default DeNovoArticleStatus;

function checkErrorsInTitle(title) {
  return title.length === 0;
}

function checkErrorsInAuthors(authors) {
  return authors.length === 0;
}

// useEffect(() => {
//   let oneMinuteInMilliseconds = 1000 * 60;
//   let timeDifferenceInMilliseconds = moment(currentTimestamp) - moment(denovoArticleAutoSaveTimeStamp);
//   let timeDifferenceInMinutes = timeDifferenceInMilliseconds / oneMinuteInMilliseconds;
//   console.log(timeDifferenceInMinutes);
//   console.log(moment(denovoArticleAutoSaveTimeStamp).fromNow())

// }, [denovoArticleData]);

// const _updateDenovoArticleToDB = async ({ denovoArticleData, JCID, PMID }) => {

//   try {
//     const abstractContentRaw = convertToRaw(denovoArticleData.abstractEditorState.getCurrentContent());
//     const abstractContentHTML = draftToHtml(abstractContentRaw);
//     const articleBodyContentRaw = convertToRaw(denovoArticleData.articleBodyEditorState.getCurrentContent());
//     const articleBodyContentHTML = draftToHtml(articleBodyContentRaw);
//     let res = await api.patchDenovoArticle(
//       JCID,
//       userEmail,
//       {
//         title: denovoArticleData.title,
//         abstractContent: abstractContentHTML,
//         bodyContent: articleBodyContentHTML,
//         authors: denovoArticleData.authors,
//         cmeCredits: denovoArticleData.cmeCredits,
//         PMID,
//         getLock: false,
//       }
//     );

//     if (res?.status === 201) {
//       dispatch(setDenovoAutosaveTimestamp(moment().toISOString()));
//       dispatch(getJournalClubArticle(JCID, PMID, userEmail));
//       dispatch(setDirty(false));
//       dispatch(closeDenovoArticleInEditMode());
//     }

//   } catch (error) {
//     setIsDenovoArticleLockedByCurrentUser(false);
//     setOpenLockStatusDialogBox(true);
//     let errorMessaage = error?.response?.data?.message ||
//       JSON.stringify(
//         {
//           ...error?.response?.data,
//           status: error?.response?.status
//         }
//       );
//     setLockStatusMessage(errorMessaage);
//     dispatch(closeDenovoArticleInEditMode());
//   }
// }

// const _autoSave = async ({ denovoArticleData, JCID, PMID }) => {
//   try {
//     const abstractContentRaw = convertToRaw(denovoArticleData.abstractEditorState.getCurrentContent());
//     const abstractContentHTML = draftToHtml(abstractContentRaw);
//     const articleBodyContentRaw = convertToRaw(denovoArticleData.articleBodyEditorState.getCurrentContent());
//     const articleBodyContentHTML = draftToHtml(articleBodyContentRaw);
//     let res = await api.patchDenovoArticle(
//       JCID,
//       userEmail,
//       {
//         title: denovoArticleData.title,
//         abstractContent: abstractContentHTML,
//         bodyContent: articleBodyContentHTML,
//         authors: denovoArticleData.authors,
//         cmeCredits: denovoArticleData.cmeCredits,
//         PMID,
//         getLock: true
//       }
//     );
//     if (res?.status === 201) {
//       dispatch(setDenovoAutosaveTimestamp(moment().toISOString()));
//       dispatch(getJournalClubArticle(JCID, PMID, userEmail, false));
//       dispatch(setDirty(false));
//     }

//   } catch (error) {
//     console.log(error);
//     setIsDenovoArticleLockedByCurrentUser(false);
//   }
// }

// const handleEditArticle = async () => {
//   try {
//     let lockResponse = await api.acquireLock(
//       jcDetails?.JCID,
//       PMID,
//       userEmail
//     );
//     if (lockResponse?.status === 200) {
//       if (lockResponse?.data?.lockAcquired === true) {
//         setIsDenovoArticleLockedByCurrentUser(true);
//         dispatch(openDenovoArticleInEditMode());
//       } else {
//         let { lockedBy } = lockResponse?.data;
//         let userRoleInJCResponse = await api.fetchUserRoleInJC(
//           jcDetails.JCID,
//           lockedBy
//         );
//         let userFullName = `${userRoleInJCResponse?.data?.firstName} ${userRoleInJCResponse?.data?.lastName}`;
//         setIsDenovoArticleLockedByCurrentUser(false);
//         setOpenLockStatusDialogBox(true);
//         setLockStatusMessage(
//           `The article is currently locked by ${userFullName}`
//         );
//       }
//     } else {
//       setIsDenovoArticleLockedByCurrentUser(false);
//       setOpenLockStatusDialogBox(true);
//       setLockStatusMessage(
//         JSON.stringify({
//           ...lockResponse?.data,
//           status: lockResponse?.status,
//         })
//       );
//     }
//   } catch (error) {
//     let errorMessaage =
//       error?.response?.data?.message ||
//       JSON.stringify({
//         ...error?.response?.data,
//         status: error?.response?.status,
//       });
//     setIsDenovoArticleLockedByCurrentUser(false);
//     setOpenLockStatusDialogBox(true);
//     setLockStatusMessage(errorMessaage);
//   }
// };
