import axios from 'axios';
import { token_name_list } from '../constants/constants';
import connectionInstance from '../utils/connection';
import { generateSignedRequest } from '../utils/awsCognitoUtils';

export const url = process.env.REACT_APP_API_URL;
const token = localStorage.getItem(token_name_list.ID_TOKEN);

export const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

// URLs
export const SEARCH_KEYWORDS_URL = `${url}/keywords/auto-complete`;
export const SEARCH_SOURCES_URL = `${url}/sources/auto-complete`;
export const SEARCH_JC_URL = `${url}/jc/auto-complete`;
export const SEARCH_ARTICLES_URL = `${url}/articles`;
export const SEARCH_PUBMED_URL = `${url}/articles/searchPubmed`;
export const DEFAULTS_URL = `${url}/defaults`;

const encodeGetParams = params => Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
const encodeEmail = (email) => email.split('@').join('%40')
// API calls
export const fetchDefaults = () => connectionInstance.get(DEFAULTS_URL);
export const fetchKeyWords = () => connectionInstance.get(`${url}/keywords`);
export const fetchSources = () => connectionInstance.get(`${url}/sources`);
export const fetchInstitutions = () => connectionInstance.get(`${url}/institutions`);
export const fetchProfessions = () => connectionInstance.get(`${url}/professions`);
export const fetchAreaOfInterest = () => connectionInstance.get(`${url}/areas-of-interest`);
export const createNewUser = (data) => connectionInstance.post(`${url}/users`, encodeGetParams(data));
export const fetchUserDetails = (userEmail) => connectionInstance.get(`${url}/users/${userEmail}`);
export const updateUserDetails = (data, userEmail) => connectionInstance.patch(`${url}/users/${userEmail}`, encodeGetParams(data));
export const fetchUserFeed = (userEmail, queryParams) => connectionInstance.get(
  `${url}/userFeed/${userEmail}?${encodeGetParams(queryParams)}`
);

export const fetchUserSavedArticles = (userEmail, queryParams) => connectionInstance.get(
  `${url}/userSavedArticles/${userEmail}?${encodeGetParams(queryParams)}`,
);
export const searchKeyWords = (search) => connectionInstance.get(`${url}/keywords/auto-complete/${search}`);
export const searchSources = (search) => connectionInstance.get(`${url}/sources/auto-complete/${search}`);
export const fetchArticle = (PMID, userEmail) => connectionInstance.get(`${url}/articles/${PMID}/${userEmail}`);
export const fetchJournalClubArticle = (JCID, PMID, userEmail) => connectionInstance.get(`${url}/jc/${JCID}/${PMID}/${userEmail}`);
export const fetchUserRoleInJC = (JCID, userEmail) => connectionInstance.get(`${url}/jc/${JCID}/members/${userEmail}`);
export const fetchArticleVoters = (JCID, PMID, userEmail, voter_type) => connectionInstance.get(`${url}/articles/${PMID}/${userEmail}/${JCID}/type/${voter_type}`);
export const postArticleActions = (PMID, userEmail, JCID, Verb) => connectionInstance.post(`${url}/articles/${PMID}/${userEmail}/${JCID}/${Verb}`, encodeGetParams({}));

export const fetchJournalClubs = (userEmail) => connectionInstance.get(`${url}/jc/${userEmail}`);
export const fetchJournalClubDetails = (userEmail, JCID) => connectionInstance.get(`${url}/jc/${JCID}/${userEmail}`); // this endpoint takes both JCID and urlSlug
export const fetchContributingMembers = (JCID, queryParams) => connectionInstance.get(
  `${url}/jc/${JCID}/members`, encodeGetParams({
    ...queryParams,
    role: 'contributor'
  }
  ),
);
// /summarize/{email}/{fileType}
export const createJournalClub = (data = {}) => connectionInstance.post(`${url}/jc`, encodeGetParams(data));
export const editJournalClub = (urlSlug, data = {}) => connectionInstance.patch(`${url}/jc/${urlSlug}`, encodeGetParams(data));
export const acceptJCInvite = (JCID, userEmail) => connectionInstance.post(`${url}/jc/${JCID}/${userEmail}/invite/accept`, encodeGetParams({}));
export const rejectJCInvite = (JCID, userEmail) => connectionInstance.post(`${url}/jc/${JCID}/${userEmail}/invite/reject`, encodeGetParams({}));
export const joinJC = (JCID, userEmail, data = {}) => connectionInstance.post(`${url}/jc/${JCID}/${userEmail}/action/join`, encodeGetParams(data));
export const leaveJC = (JCID, userEmail) => connectionInstance.post(`${url}/jc/${JCID}/${userEmail}/action/leave`, encodeGetParams({}));
export const acceptCmeDisclosure = (JCID, userEmail) => connectionInstance.patch(
  `${url}/jc/${JCID}/members/${userEmail}/update/cme`,
  encodeGetParams({
    acceptedCmeDisclosure: true
  })
);
export const rejectCmeDisclosure = (JCID, userEmail) => connectionInstance.patch(
  `${url}/jc/${JCID}/members/${userEmail}/update/cme`,
  encodeGetParams({
    acceptedCmeDisclosure: false
  })
);
export const addInstitution = (data = {}) => connectionInstance.post(
  `${url}/institutions`,
  encodeGetParams(data)
);
export const editInstitution = (institutionName, data = {}) => connectionInstance.patch(
  `${url}/institutions/${institutionName}`, 
  encodeGetParams(data)
); 
export const updateJcInstitutions = (urlSlug, data = {}) => connectionInstance.post(
  `${url}/jc/${urlSlug}/institutions`, 
  encodeGetParams(data)
);

//SuperAdmin Functios
export const approveJournalClub = (urlSlug, data = {}) => connectionInstance.patch(`${url}/jc/approve/${urlSlug}`, encodeGetParams(data));


// Comments
export const fetchCommentAndNotes = (JCID, PMID, userEmail) => connectionInstance.get(`${url}/comments/${JCID}/${PMID}/${userEmail}?all=true`);
export const postCommentActions = (commentId, userEmail, Verb) => connectionInstance.post(`${url}/comments/${commentId}/${userEmail}/action/${Verb}`, encodeGetParams({}));
export const postCommentNotes = (JCID, PMID, userEmail, data) => connectionInstance.post(`${url}/comments/${JCID}/${PMID}/${userEmail}`, encodeGetParams(data));
export const postReportComment = (commentId, userEmail, data) => connectionInstance.post(`${url}/comments/${commentId}/${userEmail}/action/report`, encodeGetParams(data));
export const fetchReplies = (commentId, userEmail) => connectionInstance.get(`${url}/comments/${commentId}/${userEmail}`);
export const deleteComment = (commentId) => connectionInstance.delete(`${url}/comments/${commentId}`);
export const deleteCommentMedia = (commentId, fileName) => connectionInstance.delete(`${url}/comments/${commentId}/media/fileName`);
export const fetchSignedUrlS3 = (commentId, fileData) => connectionInstance.post(`${url}/comments/uploadUrl/${commentId}`, encodeGetParams(fileData));
export const updateComment = (commentId, userEmail, commentData) => connectionInstance.patch(`${url}/comments/${commentId}/${userEmail}`, encodeGetParams(commentData));


// DENOVO ARTICLE 
export const postDenovoArticle = (JCID, userEmail, data = {}) => connectionInstance.post(`${url}/jc/${JCID}/create-article/${userEmail}`, encodeGetParams(data));
export const postDenovoSummary = (userEmail,fileType,data) => connectionInstance.post(encodeURI(`${url}/summarize/${userEmail}/${fileType}`),encodeGetParams(data));
export const patchDenovoArticle = (JCID, userEmail, data = {}) => connectionInstance.patch(`${url}/jc/${JCID}/create-article/${userEmail}`, encodeGetParams(data));
export const fetchDenovoEditHistory = (JCID, PMID) => connectionInstance.get(`${url}/jc/${JCID}/editors/${PMID}`);
export const acquireLock = (JCID, PMID, userEmail) => connectionInstance.get(`${url}/jc/${JCID}/acquire-lock/${PMID}/${userEmail}?${encodeGetParams({ authUserEmail: userEmail })}`);
export const postDenovoEmail = (JCID, data) => connectionInstance.post(encodeURI(`${url}/jc/${JCID}/send-mail`), encodeGetParams(data));
export const deleteDenovoArticle = (PMID, userEmail) => connectionInstance.delete(`${url}/articles/${PMID}/${userEmail}`)
//Public endpoints
export const fetchJcPublicData = (urlSlug) => connectionInstance.get(`${url}/public/jc/${urlSlug}`);
export const fetchJcArticlePublicData = (urlSlug, PMID) => connectionInstance.get(`${url}/public/jc/${urlSlug}/${PMID}`);
export const fetchArticlePublicData = (PMID) => connectionInstance.get(`${url}/public/articles/${PMID}`);
export const postResendVerification = (email) => connectionInstance.get(`${url}/public/verify/resend-email/${email}`);

// JC Reports
export const fetchJCReports = (JCID, queryParams) =>
  connectionInstance.get(`${url}/reports/jc/${JCID}?${encodeGetParams(queryParams)}`);
export const fetchMembershipReports = (JCID, queryParams) =>
  connectionInstance.get(`${url}/reports/jc/${JCID}/members?${encodeGetParams(queryParams)}`);
export const fetchCommentNotesReports = (JCID, queryParams) =>
  connectionInstance.get(`${url}/reports/jc/${JCID}/flaggednc?${encodeGetParams(queryParams)}`);
export const fetchPublishReports = (JCID, queryParams) =>
  connectionInstance.get(`${url}/reports/jc/${JCID}/articles?${encodeGetParams(queryParams)}`);
export const fetchEngagementReports = (JCID, queryParams) =>
  connectionInstance.get(`${url}/reports/jc/${JCID}/articles/stats?${encodeGetParams(queryParams)}`);

export const fetchUserRoport = (JCID, queryParams, currentMemberId) =>
  connectionInstance.get(
    `${url}/reports/jc/${JCID}/member/${currentMemberId}?${encodeGetParams(queryParams)}`
  );
export const fetchUserActivityRoport = (userEmail,queryParams) =>
  connectionInstance.get(
    `${url}/users/${userEmail}/activity/report?${encodeGetParams(queryParams)}`
  );
export const fetchAllUserActivityRoport = (userEmail,queryParams) =>
  connectionInstance.get(
    `${url}/users?${encodeGetParams(queryParams)}`
  );
export const fetchUserActivityDownloadRoport = (userEmail,queryParams) =>
  connectionInstance.get(
    `${url}/users/${userEmail}/activity/report/download?${encodeGetParams(queryParams)}`
  );
export const fetchDownloadReport = (JCID, parameter, currentLoginUser, queryParameters) =>
  connectionInstance.get(`${url}/reports/jc/${JCID}/${currentLoginUser}/download/${parameter}?${encodeGetParams(queryParameters)}`);

// Sitemaps /public/sitemap

export const fetchSitemap = () => connectionInstance.get(`${url}/public/sitemap`);
export const fetchJcSitemap = (urlSlug) => connectionInstance.get(`${url}/public/jc/${urlSlug}/sitemap`);
export const fetchSourceSitemap = (sourceId) => connectionInstance.get(`${url}/public/sources/${sourceId}/sitemap`);
export const fetchJcRss = (urlSlug) => connectionInstance.get(`${url}/public/jc/${urlSlug}/rss`);

// /jc/send-invites/{invitees_email
export const postJCInviteCsv = (JCID,data) => connectionInstance.post(encodeURI(`${url}/jc/${JCID}/send-invites`),encodeGetParams(data));
export const postJCInviteUser = (JCID,userEmail,data) => connectionInstance.post(`${url}/jc/${JCID}/members/${userEmail}/update`,encodeGetParams(data));
export const updateJCMember = (JCID,userEmail,data) => connectionInstance.patch(`${url}/jc/${JCID}/members/${userEmail}/update`,encodeGetParams(data));
export const removeJCMember = (JCID,userEmail) => connectionInstance.delete(`${url}/jc/${JCID}/members/${userEmail}/update`);
export const postInviteAction = (JCID,userEmail,action,data) => connectionInstance.post(`${url}/jc/${JCID}/${userEmail}/request/${action}`,encodeGetParams(data));
// /jc/{slug}/{email of the requesting user}/request/<string:action>
