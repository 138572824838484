import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#EF5B50',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#EF5B50',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? '#EF5B50'
          : '#EF5B50',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 19,
    height: 19,
  },
  '& .MuiSwitch-track': {
    borderRadius: 13,
    backgroundColor: '#EF5B50',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },
}));

export default function JCSwitch(props) {
  const {allCommentsCount, jcCommentsCount,handleToggleClick} = props
  const [showAll,setShowAll] = React.useState(false);

  const handleSwitch = () =>{
    setShowAll(!showAll)
    handleToggleClick()
    
  }
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant='BODY_COPY1_RALEWAY' color={showAll ? "#262626" : "#848484"}>This JC Comments ({jcCommentsCount})</Typography>
        {/* <Typography variant='BODY_COPY1_BITTER'  color={showAll ? "#262626" : "#848484"}>({jcCommentsCount})</Typography> */}
        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onClick={handleSwitch}/>
        <Typography variant='BODY_COPY1_RALEWAY' color={showAll ? "#848484" : '#262626'}>All Comments ({allCommentsCount})</Typography>
        {/* <Typography variant='BODY_COPY1_BITTER'  color={showAll ? "#262626" : "#848484"}>({ allCommentsCount })</Typography> */}
      </Stack>
    </FormGroup>
  );
}
