import React, { useState, useContext } from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from "react-redux";
import {
    SupportArticle,
  SupportedArticle,
  NoteIcon,
  NotedIcon
} from '../../assets';
import SmoothScrolling from '../../utils/smoothScrolling';

import { AuthContext } from '../../context/AuthContext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  textAlign: 'initial',
}));


function DiscussionActions(props) {
  const {isDisabled = false} = props
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;

  const dispatch = useDispatch();
  const {
    PMID,
    numberOfSupporters,
    numberOfOpposers,
    noteAddedByUser,
    numberOfNotes
  } = useSelector((state) => state.article.articleData);

  const goToSection=(id)=>{
    const targetSection = document.getElementById(id);
    if(targetSection != null && !isDisabled) {
      window.scrollTo({
        top:targetSection.offsetTop-200,
        behavior:"smooth",
      });
    }
  }

  return (
    <React.Fragment>
      <Grid item>
        <Item sx={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" sx={{
            fontFamily: 'bitter', fontSize: '16px', fontWeight: '700', width: '100%', height: '35px',padding:0, textTransform: 'none', "&.MuiButtonBase-root:hover": {
              bgcolor: "#EF5B50", cursor: 'default'},
              "&.Mui-disabled": {bgcolor:"#838383",color:'#FFFFFF'}
          }}
            disableElevation
            disableRipple
            disabled={isDisabled}>
            Discussion Actions
          </Button>

          {/*Support Article */}
          <Box sx={{
            backgroundColor: '#F3F3F3', textAlign: 'left', display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            cursor: isDisabled ? 'default': 'pointer'
            }} mt={1} pt={1.5} pb={1} pl={1.8} onClick={() => goToSection("Support")}>

            <Box sx={{ display: 'inline-block' }} >
              <SupportArticle width='28px' height="27px" />
            </Box>

            <Typography variant="BODY_COPY1_RALEWAY_DESKTOP" className="desktop-actions"
              sx={{ marginTop: '-5px', marginLeft: "8px" }} color={isDisabled ? "#838383" : "#262626"}>
              Support Article
              {!isDisabled &&
              <Typography variant="BODY_COPY2_RALEWAY" color="#838383" sx={{display:'block'}}>
                {(numberOfSupporters + numberOfOpposers) +" peers already rated"}
              </Typography>
              }
            </Typography>
          </Box>


          {/* Notes */}
          <Box sx={{
            backgroundColor: '#F3F3F3', textAlign: 'left', display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            cursor: isDisabled ? 'default': 'pointer'
          }} mt={1} pt={1} pb={1} pl={1.8} onClick={() => goToSection("Notes")}>

            <Box sx={{ display: 'inline-block' }} >
                {noteAddedByUser && !isDisabled ?
                <NotedIcon width='28px' height="27px" />
                :
                <NoteIcon width='28px' height="27px" />
                }
            </Box>
            <Typography variant="BODY_COPY1_RALEWAY_DESKTOP" className="desktop-actions"
              sx={{ marginTop: '0px', marginLeft: "8px" }} color={isDisabled ? "#838383" : "#262626"}>
                Note
              {!isDisabled &&
              <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" color="#838383" component="span" sx={{ fontSize: '12px' }} mx={0.5}>
              {"("+ numberOfNotes +")"}
              </Typography>
              }
            </Typography>
          </Box>

        </Item>
      </Grid>



    </React.Fragment>
  )
}

export default DiscussionActions